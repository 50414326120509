import dsgnsys_SettingsPanelContainerGfpp from '../.components/SettingsPanelContainer/editor/SettingsPanelContainer.gfpp';
import dsgnsys_SettingsPanelContainerMetadata from '../.components/SettingsPanelContainer/editor/SettingsPanelContainer.editorMetadata';
import dsgnsys_WbuBackButtonGfpp from '../.components/WbuBackButton/editor/WbuBackButton.gfpp';
import dsgnsys_WbuBackButtonMetadata from '../.components/WbuBackButton/editor/WbuBackButton.editorMetadata';
import dsgnsys_WbuNotificationBannerGfpp from '../.components/WbuNotificationBanner/editor/WbuNotificationBanner.gfpp';
import dsgnsys_WbuNotificationBannerMetadata from '../.components/WbuNotificationBanner/editor/WbuNotificationBanner.editorMetadata';
import dsgnsys_McokSvgGfpp from '../.components/McokSvg/editor/McokSvg.gfpp';
import dsgnsys_McokSvgMetadata from '../.components/McokSvg/editor/McokSvg.editorMetadata';
import dsgnsys_WbuAccordionSectionGfpp from '../.components/WbuAccordionSection/editor/WbuAccordionSection.gfpp';
import dsgnsys_WbuAccordionSectionMetadata from '../.components/WbuAccordionSection/editor/WbuAccordionSection.editorMetadata';
import dsgnsys_WbuButtonGfpp from '../.components/WbuButton/editor/WbuButton.gfpp';
import dsgnsys_WbuButtonMetadata from '../.components/WbuButton/editor/WbuButton.editorMetadata';
import dsgnsys_WbuCheckboxGroupGfpp from '../.components/WbuCheckboxGroup/editor/WbuCheckboxGroup.gfpp';
import dsgnsys_WbuCheckboxGroupMetadata from '../.components/WbuCheckboxGroup/editor/WbuCheckboxGroup.editorMetadata';
import dsgnsys_WbuDrillInListItemGfpp from '../.components/WbuDrillInListItem/editor/WbuDrillInListItem.gfpp';
import dsgnsys_WbuDrillInListItemMetadata from '../.components/WbuDrillInListItem/editor/WbuDrillInListItem.editorMetadata';
import dsgnsys_WbuDropdownGfpp from '../.components/WbuDropdown/editor/WbuDropdown.gfpp';
import dsgnsys_WbuDropdownMetadata from '../.components/WbuDropdown/editor/WbuDropdown.editorMetadata';
import dsgnsys_WbuEmptyStateGfpp from '../.components/WbuEmptyState/editor/WbuEmptyState.gfpp';
import dsgnsys_WbuEmptyStateMetadata from '../.components/WbuEmptyState/editor/WbuEmptyState.editorMetadata';
import dsgnsys_WbuHorizontalTabsGfpp from '../.components/WbuHorizontalTabs/editor/WbuHorizontalTabs.gfpp';
import dsgnsys_WbuHorizontalTabsMetadata from '../.components/WbuHorizontalTabs/editor/WbuHorizontalTabs.editorMetadata';
import dsgnsys_WbuLeftTextButtonGfpp from '../.components/WbuLeftTextButton/editor/WbuLeftTextButton.gfpp';
import dsgnsys_WbuLeftTextButtonMetadata from '../.components/WbuLeftTextButton/editor/WbuLeftTextButton.editorMetadata';
import dsgnsys_WbuNumericInputGfpp from '../.components/WbuNumericInput/editor/WbuNumericInput.gfpp';
import dsgnsys_WbuNumericInputMetadata from '../.components/WbuNumericInput/editor/WbuNumericInput.editorMetadata';
import dsgnsys_WbuRadioButtonGroupGfpp from '../.components/WbuRadioButtonGroup/editor/WbuRadioButtonGroup.gfpp';
import dsgnsys_WbuRadioButtonGroupMetadata from '../.components/WbuRadioButtonGroup/editor/WbuRadioButtonGroup.editorMetadata';
import dsgnsys_WbuRichTextGfpp from '../.components/WbuRichText/editor/WbuRichText.gfpp';
import dsgnsys_WbuRichTextMetadata from '../.components/WbuRichText/editor/WbuRichText.editorMetadata';
import dsgnsys_WbuSectionDividerGfpp from '../.components/WbuSectionDivider/editor/WbuSectionDivider.gfpp';
import dsgnsys_WbuSectionDividerMetadata from '../.components/WbuSectionDivider/editor/WbuSectionDivider.editorMetadata';
import dsgnsys_WbuSliderGfpp from '../.components/WbuSlider/editor/WbuSlider.gfpp';
import dsgnsys_WbuSliderMetadata from '../.components/WbuSlider/editor/WbuSlider.editorMetadata';
import dsgnsys_WbuTextInputGfpp from '../.components/WbuTextInput/editor/WbuTextInput.gfpp';
import dsgnsys_WbuTextInputMetadata from '../.components/WbuTextInput/editor/WbuTextInput.editorMetadata';
import dsgnsys_WbuTextInputMultilineGfpp from '../.components/WbuTextInputMultiline/editor/WbuTextInputMultiline.gfpp';
import dsgnsys_WbuTextInputMultilineMetadata from '../.components/WbuTextInputMultiline/editor/WbuTextInputMultiline.editorMetadata';
import dsgnsys_WbuThumbnailsGfpp from '../.components/WbuThumbnails/editor/WbuThumbnails.gfpp';
import dsgnsys_WbuThumbnailsMetadata from '../.components/WbuThumbnails/editor/WbuThumbnails.editorMetadata';
import dsgnsys_WbuToggleSwitchGfpp from '../.components/WbuToggleSwitch/editor/WbuToggleSwitch.gfpp';
import dsgnsys_WbuToggleSwitchMetadata from '../.components/WbuToggleSwitch/editor/WbuToggleSwitch.editorMetadata';
import dsgnsys_WsrButtonGfpp from '../.components/WsrButton/editor/WsrButton.gfpp';
import dsgnsys_WsrButtonMetadata from '../.components/WsrButton/editor/WsrButton.editorMetadata';
import dsgnsys_WutColorPickerGfpp from '../.components/WutColorPicker/editor/WutColorPicker.gfpp';
import dsgnsys_WutColorPickerMetadata from '../.components/WutColorPicker/editor/WutColorPicker.editorMetadata';
import dsgnsys_WutRadioButtonGfpp from '../.components/WutRadioButton/editor/WutRadioButton.gfpp';
import dsgnsys_WutRadioButtonMetadata from '../.components/WutRadioButton/editor/WutRadioButton.editorMetadata';
import dsgnsys_WutTabbedContentGfpp from '../.components/WutTabbedContent/editor/WutTabbedContent.gfpp';
import dsgnsys_WutTabbedContentMetadata from '../.components/WutTabbedContent/editor/WutTabbedContent.editorMetadata';


const dsgnsys_SettingsPanelContainer = {
  gfpp: dsgnsys_SettingsPanelContainerGfpp,
  metadata: dsgnsys_SettingsPanelContainerMetadata
};

const dsgnsys_WbuBackButton = {
  gfpp: dsgnsys_WbuBackButtonGfpp,
  metadata: dsgnsys_WbuBackButtonMetadata
};

const dsgnsys_WbuNotificationBanner = {
  gfpp: dsgnsys_WbuNotificationBannerGfpp,
  metadata: dsgnsys_WbuNotificationBannerMetadata
};

const dsgnsys_McokSvg = {
  gfpp: dsgnsys_McokSvgGfpp,
  metadata: dsgnsys_McokSvgMetadata
};

const dsgnsys_WbuAccordionSection = {
  gfpp: dsgnsys_WbuAccordionSectionGfpp,
  metadata: dsgnsys_WbuAccordionSectionMetadata
};

const dsgnsys_WbuButton = {
  gfpp: dsgnsys_WbuButtonGfpp,
  metadata: dsgnsys_WbuButtonMetadata
};

const dsgnsys_WbuCheckboxGroup = {
  gfpp: dsgnsys_WbuCheckboxGroupGfpp,
  metadata: dsgnsys_WbuCheckboxGroupMetadata
};

const dsgnsys_WbuDrillInListItem = {
  gfpp: dsgnsys_WbuDrillInListItemGfpp,
  metadata: dsgnsys_WbuDrillInListItemMetadata
};

const dsgnsys_WbuDropdown = {
  gfpp: dsgnsys_WbuDropdownGfpp,
  metadata: dsgnsys_WbuDropdownMetadata
};

const dsgnsys_WbuEmptyState = {
  gfpp: dsgnsys_WbuEmptyStateGfpp,
  metadata: dsgnsys_WbuEmptyStateMetadata
};

const dsgnsys_WbuHorizontalTabs = {
  gfpp: dsgnsys_WbuHorizontalTabsGfpp,
  metadata: dsgnsys_WbuHorizontalTabsMetadata
};

const dsgnsys_WbuLeftTextButton = {
  gfpp: dsgnsys_WbuLeftTextButtonGfpp,
  metadata: dsgnsys_WbuLeftTextButtonMetadata
};

const dsgnsys_WbuNumericInput = {
  gfpp: dsgnsys_WbuNumericInputGfpp,
  metadata: dsgnsys_WbuNumericInputMetadata
};

const dsgnsys_WbuRadioButtonGroup = {
  gfpp: dsgnsys_WbuRadioButtonGroupGfpp,
  metadata: dsgnsys_WbuRadioButtonGroupMetadata
};

const dsgnsys_WbuRichText = {
  gfpp: dsgnsys_WbuRichTextGfpp,
  metadata: dsgnsys_WbuRichTextMetadata
};

const dsgnsys_WbuSectionDivider = {
  gfpp: dsgnsys_WbuSectionDividerGfpp,
  metadata: dsgnsys_WbuSectionDividerMetadata
};

const dsgnsys_WbuSlider = {
  gfpp: dsgnsys_WbuSliderGfpp,
  metadata: dsgnsys_WbuSliderMetadata
};

const dsgnsys_WbuTextInput = {
  gfpp: dsgnsys_WbuTextInputGfpp,
  metadata: dsgnsys_WbuTextInputMetadata
};

const dsgnsys_WbuTextInputMultiline = {
  gfpp: dsgnsys_WbuTextInputMultilineGfpp,
  metadata: dsgnsys_WbuTextInputMultilineMetadata
};

const dsgnsys_WbuThumbnails = {
  gfpp: dsgnsys_WbuThumbnailsGfpp,
  metadata: dsgnsys_WbuThumbnailsMetadata
};

const dsgnsys_WbuToggleSwitch = {
  gfpp: dsgnsys_WbuToggleSwitchGfpp,
  metadata: dsgnsys_WbuToggleSwitchMetadata
};

const dsgnsys_WsrButton = {
  gfpp: dsgnsys_WsrButtonGfpp,
  metadata: dsgnsys_WsrButtonMetadata
};

const dsgnsys_WutColorPicker = {
  gfpp: dsgnsys_WutColorPickerGfpp,
  metadata: dsgnsys_WutColorPickerMetadata
};

const dsgnsys_WutRadioButton = {
  gfpp: dsgnsys_WutRadioButtonGfpp,
  metadata: dsgnsys_WutRadioButtonMetadata
};

const dsgnsys_WutTabbedContent = {
  gfpp: dsgnsys_WutTabbedContentGfpp,
  metadata: dsgnsys_WutTabbedContentMetadata
};


export const components = {
  ['dsgnsys.SettingsPanelContainer']: dsgnsys_SettingsPanelContainer,
  ['dsgnsys.WbuBackButton']: dsgnsys_WbuBackButton,
  ['dsgnsys.WbuNotificationBanner']: dsgnsys_WbuNotificationBanner,
  ['dsgnsys.McokSvg']: dsgnsys_McokSvg,
  ['dsgnsys.WbuAccordionSection']: dsgnsys_WbuAccordionSection,
  ['dsgnsys.WbuButton']: dsgnsys_WbuButton,
  ['dsgnsys.WbuCheckboxGroup']: dsgnsys_WbuCheckboxGroup,
  ['dsgnsys.WbuDrillInListItem']: dsgnsys_WbuDrillInListItem,
  ['dsgnsys.WbuDropdown']: dsgnsys_WbuDropdown,
  ['dsgnsys.WbuEmptyState']: dsgnsys_WbuEmptyState,
  ['dsgnsys.WbuHorizontalTabs']: dsgnsys_WbuHorizontalTabs,
  ['dsgnsys.WbuLeftTextButton']: dsgnsys_WbuLeftTextButton,
  ['dsgnsys.WbuNumericInput']: dsgnsys_WbuNumericInput,
  ['dsgnsys.WbuRadioButtonGroup']: dsgnsys_WbuRadioButtonGroup,
  ['dsgnsys.WbuRichText']: dsgnsys_WbuRichText,
  ['dsgnsys.WbuSectionDivider']: dsgnsys_WbuSectionDivider,
  ['dsgnsys.WbuSlider']: dsgnsys_WbuSlider,
  ['dsgnsys.WbuTextInput']: dsgnsys_WbuTextInput,
  ['dsgnsys.WbuTextInputMultiline']: dsgnsys_WbuTextInputMultiline,
  ['dsgnsys.WbuThumbnails']: dsgnsys_WbuThumbnails,
  ['dsgnsys.WbuToggleSwitch']: dsgnsys_WbuToggleSwitch,
  ['dsgnsys.WsrButton']: dsgnsys_WsrButton,
  ['dsgnsys.WutColorPicker']: dsgnsys_WutColorPicker,
  ['dsgnsys.WutRadioButton']: dsgnsys_WutRadioButton,
  ['dsgnsys.WutTabbedContent']: dsgnsys_WutTabbedContent
};


// temporary export
export const version = "1.0.0"
