import { createEditorMetadataEntry } from '@wix/editor-elements-integrations';

export default createEditorMetadataEntry({
  allowConnectToDB: false,
  verticallyResizable: false,
  horizontallyResizable: false,
  arrangeable: false,
  pinnable: false,
  rotatable: false,
  flippable: false,
});
